import React, { Fragment, useEffect, useState } from "react";
import Editor from "@monaco-editor/react";
import "./Editor.css";
import axios from "axios";
import { useParams, useLocation } from "react-router-dom";
import { BiReset } from "react-icons/bi";
import { BsFillPlayFill, BsCodeSlash, BsTerminal } from "react-icons/bs";
import Spinner from "../../Utilities/Spinner/Spinner";
import AiCopilot from "../../Utilities/Ai-Copilot/AiCopilot";
import Draggable from "react-draggable";
import "../../Utilities/Plot-Component/PlotComponent.css";
import { ReactComponent as ClearIcon } from "../../../assets/clear.svg";

function MonacoEditor() {
  const { id } = useParams();
  const { course } = useParams();
  // const { subCourse } = useParams();
  const [loadOutput, setLoadOutput] = useState("");
  const [tab, setTab] = useState("code");
  const [inputCode, setInputCode] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [outputCode, setOutputCode] = useState("");
  const [finalOutput, setFinalOutput] = useState("");
  const [scriptData, setScriptData] = useState("");
  const [errorStatus, setErrorStatus] = useState(0);
  const [clear, setClear] = useState(false);
  const style = {
    color: "#05192d",
    backgroundColor: "rgba(243, 242, 242, 0.822)",
  };
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const plotData = params.get("plot"); //for Plot query

  useEffect(() => {
    async function getBlob(course, id) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SOURCE_FILE}/${course}.json`
          // `${process.env.REACT_APP_SOURCE_FILE}/${course}/${subCourse}.json`
        );
        return response.data[id];
      } catch (error) {
        throw new Error("Something went wrong");
      }
    }

    // get the script-data from the blob storage
    getBlob(course, id)
      .then((data) => {
        setScriptData(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [course, id]);

  const submitCode = async () => {
    setLoadOutput("loading");

    let requestBody;
    let plotFlag = 0; // maintains the status of the flag

    requestBody = {
      source_code: btoa(replaceReadCSVLine(inputCode)),
      language_id: 10,
    };

    // if plots are to be displayed
    if (plotData) {

      // for plot folder
      if (inputCode === scriptData.code) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_PLOT_FILE}/${plotData}.png`,
            {
              responseType: "blob",
            }
          );
          const imageUrl = URL.createObjectURL(response.data);
          setImageUrl(imageUrl);
          setLoadOutput("loaded");
          plotFlag = 1; // plot has been successfully displayed
        } catch (error) {
          // if the plot doesn't already exist inside the plot folder create a new one
          requestBody = {
            source_code: btoa(
              replaceReadCSVLine(replacePltShowLine(inputCode, "plots"))
            ),
            language_id: 10,
          };
          plotFlag = 2; // plot is created in the "plots" folder
          // console.log(error);
        }
      }
      // for modifedPlot folder
      else {
        requestBody = {
          source_code: btoa(
            replaceReadCSVLine(replacePltShowLine(inputCode, "modifiedPlots"))
          ),
          language_id: 10,
        };
        plotFlag = 3; // plot is created in the "modifiedPlots" folder
      }
    }

    try {

      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL_PROD}?base64_encoded=true&wait=true`,
        requestBody
      );
      // if there are no errors i.e. success response
      if (response.data.status?.id === 3) {
        setErrorStatus(0);
        setOutputCode(atob(response.data.stdout));
        if (!response.data.stdout){
          setOutputCode('');
        }

        if (plotFlag === 2) {
  
          const response = await axios.get(
            `${process.env.REACT_APP_PLOT_FILE}/${plotData}.png`,                
            {
              responseType: "blob",
            }
          );
          const imageUrl = URL.createObjectURL(response.data);
          setImageUrl(imageUrl);
        } else 
        if (plotFlag === 3) {
   
          const response = await axios.get(
            `${process.env.REACT_APP_MODIFIED_PLOT_FILE}/${plotData}.png`,
            {
              responseType: "blob",
            }
          );
          const imageUrl = URL.createObjectURL(response.data);
          setImageUrl(imageUrl);
        }
        setLoadOutput("loaded");
        return;

        // if there's some error in the response from judge0 api
      } else {
        setErrorStatus(1);
        setOutputCode(atob(response.data.stderr));
        setLoadOutput("loaded");

        return;
      }
    } catch (error) {
      // if there's some error in the axios request
      setTimeout(() => {
        setLoadOutput("loaded");
      }, 5000);
      console.error(error);
    }
  };

  // if there's a plot to be made then show no output (keeping the console empty)
  useEffect(() => {
    // if (plotData) {
    //   setFinalOutput(`Your Output:\n`);
    // } else {
      setFinalOutput(`Your Output:\n${outputCode}`);
    // }
    setLoadOutput("loaded");
  }, [outputCode]);

  // load the script data as soon as it's ready
  useEffect(() => {
    if (scriptData) {
      setInputCode(scriptData.code);
    }
  }, [scriptData]);

  function handleInputChange(value) {
    setInputCode(value);
  }

  // sets the editor-script modified by the user to it's original content
  function handleReset() {
    setInputCode(scriptData.code);
    setOutputCode("");
  }

  function handleCodeTab() {
    setTab("code");
  }

  function handleDojobotTab() {
    setTab("DojoBot");
  }

  // replace plt.show() with relevant code to display it properly
  function replacePltShowLine(inputString, folder) {
    const updatedString = inputString.replace(
      /plt\.show\(\)/g,
      `from azure.storage.blob import BlobServiceClient
import os
filename = '${plotData}.png'
plt.savefig(filename)
folder_name = '${folder}/'
blob_name = folder_name + filename
account_name = 'staticasssets'
account_key = '${process.env.REACT_APP_BLOB_ACCOUNT_KEY}'
container_name = '${process.env.REACT_APP_CONTAINER_NAME}'
blob_service_client = BlobServiceClient(account_url=f"https://{account_name}.blob.core.windows.net", credential=account_key)
blob_client = blob_service_client.get_blob_client(container=container_name, blob=blob_name)
with open(filename, 'rb') as file:
  blob_client.upload_blob(file, overwrite=True)
os.remove(filename)
print(f"Press the Green Icon to drag the plot")`
    );

    return updatedString;
  }

  // replace the pd.read statement to some relevant code
  function replaceReadCSVLine(inputString) {
    const headers = {
      "User-Agent":
        "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.3",
    };

    const urlRegex =
      /(\b\w*df)\s*=\s*pd\.(read_csv|read_excel)\(['"]([^'"]+)['"]\)/g;
    const matches = Array.from(inputString.matchAll(urlRegex));

    if (matches.length === 0) {
      return inputString; // Return the input string unchanged if no matches found
    }

    let updatedString = inputString;
    for (const match of matches) {
      const variableName = match[1]; // Extract the variable name ending with 'df' or just 'df'
      const method = match[2]; // Extract the method name (read_csv or read_excel)
      const url = match[3]; // Extract the URL from the matched pattern

      const replacement = `import requests
from io import ${method === "read_csv" ? "StringIO" : "BytesIO"}
import pandas as pd

url = '${url}'
headers = ${JSON.stringify(headers)}
response = requests.get(url, headers=headers)
data = ${
        method === "read_csv"
          ? "StringIO(response.text)"
          : "BytesIO(response.content)"
      }
${variableName} = pd.${method}(data)`;

      updatedString = updatedString.replace(match[0], replacement);
    }

    return updatedString;
  }

  const removeImage = () => {
    setImageUrl("");
  };
  function resetChatVariable() {
    setClear(false);
  }
  function clearChat() {
    setClear(true);
  }


  return (
    <Fragment>
      <div className="component">
        <div
          className="editorArea"
          style={{ height: tab === "code" ? "350px" : "510px" }}
        >
          <div className="editorButton up">
            {/* {tab === "DojoBot" ?( <button className="clearIcon" */}
            {tab === "DojoBot" ? (
              <button onClick={clearChat}>
                <div style={{ position: "relative", left: "5px" }}>
                  <ClearIcon height="36px" width="37px" />
                </div>
                Clear
              </button>
            ) : (
              <button
                style={{
                  width: "0px !important",
                  height: "0px !important",
                  padding: "0px",
                  border: "0px",
                }}
              ></button>
            )}

            <div
              title="Switch to your AI Assisstant"
              className="codePy"
              onClick={handleDojobotTab}
              style={{ background: tab === "DojoBot" ? "#114375" : "#05192d" }}
            >
              <img
                src={
                  "https://datasciencedojo.com/wp-content/uploads/Logo_Tori_small-2.png"
                }
                alt="DSD Logo"
                width="17vw"
                height="17vh"
                style={{ marginRight: "3px" }}
              />
              1:1 AI Tutor
            </div>
            <div
              className="codePy"
              onClick={handleCodeTab}
              style={{ background: tab === "code" ? "#114375" : "#05192d" }}
            >
              <BsCodeSlash
                size={18}
                style={{ marginRight: "7px" }}
              ></BsCodeSlash>
              code.py
            </div>
          </div>

          <div
            style={{
              margin: "1px 0px 1px 0px  ",
              height: "72%",
              width: "98%",
              borderLeft: "3px solid rgb(5, 25, 45)",
              borderRight: "3px solid rgb(5, 25, 45)",
              borderTop: "3px solid rgb(5, 25, 45)",
              borderBottomLeftRadius: tab === "DojoBot" ? "3px" : "",
              borderBottomRightRadius: tab === "DojoBot" ? "3px" : "",
              borderBottom: tab === "DojoBot" ?"3px solid rgb(5, 25, 45)" : "",
            }}
          >
            {imageUrl && (
              <Draggable>
                <div className="draggable-image">
                  <div className="drag-handle" />
                  <img
                    className="image-size"
                    src={imageUrl}
                    alt="Your Plot"
                  />
                  <button className="remove-image-button" onClick={removeImage}>
                    &#10006;
                  </button>
                </div>
              </Draggable>
            )}
            {tab === "code" ? (
              <Editor
                options={{
                  fontSize: 13,
                  minimap: {
                    enabled: false,
                  },
                }}
                height="100%"
                width="100%"
                defaultLanguage="python"
                value={inputCode}
                onChange={handleInputChange}
                theme="theme"
                loading="Loading. . ."
              />
            ) : (
              <AiCopilot
                errorStatus={errorStatus}
                outputCode={outputCode}
                scriptData={scriptData}
                theme={style.backgroundColor}
                clear={clear}
                resetChatVariable={resetChatVariable}
              ></AiCopilot>
            )}
          </div>
          {tab === "code" ? (
            <div className="editorButton down"
            style={{
              borderLeft: "3px solid rgb(5, 25, 45)",
              borderRight: "3px solid rgb(5, 25, 45)",
              borderBottom: "3px solid rgb(5, 25, 45)",
            }}
            >
              <button title="Reset to the original code" onClick={handleReset}>
                {" "}
                <BiReset size={18} style={{ marginRight: "3px" }}>
                  {" "}
                </BiReset>
                Reset
              </button>

              <button title="Execute the code" onClick={() => submitCode()}>
                <BsFillPlayFill size={18}></BsFillPlayFill>
                Run
              </button>
              {loadOutput === "loading" ? (
                <Spinner className="spin"></Spinner>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>

      {tab !== "DojoBot" ? (
        <div className=" secondComponent component ">
          <div className="console">
            <BsTerminal></BsTerminal>
            <span>console</span>
          </div>
          <textarea
            spellCheck="false"
            style={style}
            value={finalOutput}
            className="textArea scroll"
            rows="5"
          ></textarea>
        </div>
      ) : (
        ""
      )}
    </Fragment>
  );
}

export default MonacoEditor;
