async function generateResponse(requestBody) {
    try {
        const response = await fetch("/api/generate-response", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
        });

        return response.json();
    } catch (error) {
        throw new Error(error.message);
    }
}

module.exports = {
    generateResponse,
}
