// This component acts as the main interface of the openAi Bot.

import React, { Fragment } from "react";
import "./MainChatSmall.css";
import OpenAiBot from "../Utilities/OpenAiBot/OpenAiBot";

function MainChatSmall() {

  return (
    <Fragment>
      <div className="component">
        <div className="editorArea" style={{ height: "410px" }}>
        <div className="editorTab" style={{ backgroundColor:"#114375"}}>
            <div title="Chat to your AI Assisstant" className="codePy" style={{ background:"#114375", color:"white" }} >
              <img src={"https://datasciencedojo.com/wp-content/uploads/Logo_Tori_small-2.png"}
                alt="DSD Logo"
                width="17vw"
                height="17vh"
                style={{ marginRight: "3px" }}
              />
              Dojo Chat
            </div>
          </div>

          <div
            id="chat-interface"
            style={{
              margin: "1px 0px 1px 0px",
              height: "72%",
              width: "98%",
              borderLeft: "3px solid rgb(5, 25, 45)",
              borderRight: "3px solid rgb(5, 25, 45)",
              borderTop: "3px solid rgb(5, 25, 45)",
              borderBottomLeftRadius: "3px", 
              borderBottomRightRadius: "3px" ,
              borderBottom:"3px solid rgb(5, 25, 45)" 
            }}
          >
              <OpenAiBot/>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default MainChatSmall;
