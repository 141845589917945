// This component acts as the main interface of the openAi Bot.

import React, { useEffect, Fragment } from "react";
import { Flex } from "@chakra-ui/react";
import PromptWindow from "../Utilities/PromptWindowUtil/PromptWindow.js";

function PromptWindowInterface() {

  useEffect(() => {
    // Dynamically import CSS when the component mounts
    import("./PromptWindowInterface.css");
  }, []);

  return (
    <Fragment>
      <Flex w="100%" className="smallviewToolbar">
            <div className="prompttoolbar" >
              Prompt Window
            </div>
          </Flex>
      <Flex className="main-component">
        <PromptWindow></PromptWindow>
      </Flex>
    </Fragment>
  );
}

export default PromptWindowInterface;
