import LandingPage from "./components/Helper/LandingPage.js";
import CodeRunner from "./components/Editor/Editor-Horizontal/Editor.js"; //master
import CodeRunner2 from "./components/Editor/Editor-sidebyside/Editor-sidebyside.js";
// import CodeRunner from "./components/Editor/Editor-Vertical/Editor-Vertical.js"; //dev
import MainChat from "../src/components/MainChat/ChatInterface.js"
import MainChatSmall from "./components/MainChatSmall/MainChatSmall.js";
import MainChatTuner from "./components/MainChatTuner/MainChatTuner.js";
import DaVinciChatTuner from "./components/MainChatTuner/DaVinciChatTuner.js";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import PromptWindowInterface from "./components/PromptWindow/PromptWindowInterface.js";
import PromptWindowExtendedInterface from "./components/PromptWindowExtended/PromptWindowExtendedInterface.js";

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/helper"
          element={<LandingPage />}
        />
        <Route
          path="/:course/:id"
          // path="/:course/:subCourse/:id"
          element={<CodeRunner />}  // query param data, sent directly from the url, should not be defined from the path
        // /course3/blog1?plot=plot1
        />
        <Route
          path="/SidebySide"
          element={<CodeRunner2 />}  // query param data, sent directly from the url, should not be defined from the path
        // /course3/blog1?plot=plot1
        />
        <Route
          path="/openAiBot"
          element={<MainChat />}>
        </Route>
        <Route
          path="/openAiBotSmall"
          element={<MainChatSmall />}>
        </Route>
        <Route
          path="/openAiBotTuner"
          element={<MainChatTuner />}>
        </Route>
        <Route
          path="/openAiBotDavinci"
          element={<DaVinciChatTuner />}>
        </Route>
        <Route
          path="/promptWindow"
          element={<PromptWindowInterface />}>
        </Route>
        <Route
          path="/promptWindowExtended"
          element={<PromptWindowExtendedInterface />}>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
export default App;
