// This component acts as the main interface of the openAi Bot.

import React, { useEffect, Fragment } from "react";
import { Flex } from "@chakra-ui/react";
import PromptWindowExtended from "../Utilities/PromptWindowUtil/PromptWindowExtended.js";

function PromptWindowExtendedInterface() {

  useEffect(() => {
    // Dynamically import CSS when the component mounts
    import("./PromptWindowExtendedInterface.css");
  }, []);

  return (
    <Fragment>
      <Flex className="main-component">
        <PromptWindowExtended></PromptWindowExtended>
      </Flex>
    </Fragment>
  );
}

export default PromptWindowExtendedInterface;
