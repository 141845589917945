export function sliceText(text) {
    
    const codeRegex = /```([\s\S]*?)```/g;
    const codeMatches = text.matchAll(codeRegex);
    const codeSegments = [];

    let previousEndIdx = 0;

    for (const match of codeMatches) {
      const startIdx = match.index;
      const endIdx = startIdx + match[0].length;

      const beforeCode = text.slice(previousEndIdx, startIdx).trim();
      const code = match[1].trim();

      codeSegments.push({ beforeCode, code });

      previousEndIdx = endIdx;
    }

    if (codeSegments.length === 0) {
      return null;
    }

    const afterCode = text.slice(previousEndIdx).trim();

    return codeSegments.map((segment, index) => {
      const { beforeCode, code } = segment;
      const isLastSegment = index === codeSegments.length - 1;

      return {
        beforeCode,
        code,
        afterCode: isLastSegment ? afterCode : "",
      };
    });
  }