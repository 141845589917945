import CryptoJS from "crypto-js";

const secretKey = "5aJu9b7vzqinI6KrI0KG/yjUjoAvBSmOJgw+kyPoLbFc5eDufHv7q+Uh6FBzcosFnSKQ2O1WpQx4y5XdNNncoQaa";


export const encryptData = (text) => {
    if (text) {
        const data = CryptoJS.AES.encrypt(
            JSON.stringify(text),
            secretKey
        ).toString();
        return data;
    } else {
        return text;
    }


};

export const decryptData = (text) => {
    if (!text) {
        return null;
    }
    const bytes = CryptoJS.AES.decrypt(text, secretKey);
    const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    return data;
};
