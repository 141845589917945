import React, { Fragment, useState } from "react";
import "./LandingPage.css"


const LandingPage = () => {
  const [code, setCode] = useState("");
  const [formattedCode, setFormattedCode] = useState("");

  function copyTextToClipboard(text) {
    navigator.clipboard.writeText(text)
      .then(() => {
        console.log('');
      })
      .catch((error) => {
        console.error('Error copying text: ', error);
      });
  }
  
  function openLinkInNewTab(url) {
    var win = window.open(url, "_blank");
    win.focus();
  }

  function formatPythonCode(code) {
    const codeString = JSON.stringify(code);
    return `"code": ${codeString}`;
  }
  
  // function formatPythonCode(code) {
  //   const escapedCode = code.replace(/\\/g, '\\\\').replace(/\n/g, '\\n').replace(/"/g, '\\"');
  //   const indentedCode = escapedCode.replace(/^(.*)$/gm, '    $1');
  //   const codeString = `"code": "${indentedCode}"`;
  //   console.log(codeString);
  //   return codeString;
  // }
  

  const formatCode = () => {
    // Call the format_python_code function to format the code
    const formatted = formatPythonCode(code);

    // Set the formatted code in the state
    setFormattedCode(formatted);
  };
  return (
    <Fragment>
      
      <textarea
      style={{height:"50vh", width:"100%", border:"2px solid #05192d"}}
        value={code}
        onChange={(e) => setCode(e.target.value)}
      />
      <div className="utilityButtons">
      <button onClick={formatCode}>Format Code</button>
      <button onClick={() => copyTextToClipboard(formattedCode)}>Copy</button>
      <button
        onClick={() => {
          openLinkInNewTab("https://portal.azure.com/#view/Microsoft_Azure_Storage/ContainerMenuBlade/~/overview/storageAccountId/%2Fsubscriptions%2Fdaa06d72-2245-4e5c-b0fb-3294990933a9%2FresourceGroups%2Frg-moodle-dsd001%2Fproviders%2FMicrosoft.Storage%2FstorageAccounts%2Fstaticasssets/path/open-ai-coderunner/etag/%220x8DB4DA40FB23477%22/defaultEncryptionScope/%24account-encryption-key/denyEncryptionScopeOverride~/false/defaultId//publicAccessVal/Blob");
        }}
      >
        Go to Blob
      </button>
      
      </div>
      {formattedCode && <p>Note: The following text is just to show the glimpse of the formatting, Please use the copy button above to get it copied:<br></br><br></br>{formattedCode}</p>}
    </Fragment>
  );
};

export default LandingPage;
