import React, { Fragment, useEffect, useState } from "react";
import Editor from "@monaco-editor/react";
//import "./Editor.css";
import axios from "axios";
import { useParams, useLocation } from "react-router-dom";
import { BiReset } from "react-icons/bi";
import { BsFillPlayFill, BsCodeSlash } from "react-icons/bs";
import { GiBroom } from "react-icons/gi";
import {
	PiPlayFill,
	PiArrowsCounterClockwiseFill,
	PiPaperPlaneRightFill,
	PiBroomFill,
} from "react-icons/pi";
import dsdLogo from "../../../assets/DataScienceDojologo.png"; // Adjust the path as per your actual structure
import Spinner from "../../Utilities/Spinner/Spinner";
import AiCopilot2 from "../../Utilities/Ai-Copilot2/AiCopilot2";
import Draggable from "react-draggable";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "../../Utilities/Plot-Component/PlotComponent.css";
import {
	Button,
	ButtonGroup,
	Heading,
	Flex,
	Box,
	ChakraProvider,
} from "@chakra-ui/react";

function MonacoEditor() {
	const { id } = useParams();
	const { course } = useParams();
	// const { subCourse } = useParams();
	const [loadOutput, setLoadOutput] = useState("");
	const [tab, setTab] = useState("code");
	const [inputCode, setInputCode] = useState("");
	const [imageUrl, setImageUrl] = useState("");
	const [outputCode, setOutputCode] = useState("");
	const [finalOutput, setFinalOutput] = useState("");
	const [scriptData, setScriptData] = useState("");
	const [errorStatus, setErrorStatus] = useState(0);
	const [clear, setClear] = useState(false);
	const style = {
		color: "#fff",
		backgroundColor: "#1a202c",
		border: "1px solid #ffffff29",
	};
	const location = useLocation();
	const params = new URLSearchParams(location.search);
	const plotData = params.get("plot"); //for Plot query

	// useEffect(() => inputRef.current.focus());
	useEffect(() => {
		import("./Editor-sidebyside.css");
	}, []);

	const [messages, setMessages] = useState([]);
	const [isTyping, setIsTyping] = useState(false);
	const [userMsg, setUserMsg] = useState("");
	const [dojoMsg, setDojoMsg] = useState("");
	const [temp, setTemp] = useState(0.7);
	const [maxTokens, setMaxTokens] = useState(140);
	const [topP, setTopP] = useState(0.1);

	useEffect(() => {
		async function getBlob(course, id) {
			try {
				const response = await axios.get(
					`${process.env.REACT_APP_SOURCE_FILE}/${course}.json`
					// `${process.env.REACT_APP_SOURCE_FILE}/${course}/${subCourse}.json`
				);
				return response.data[id];
			} catch (error) {
				throw new Error("Something went wrong");
			}
		}

		// get the script-data from the blob storage
		getBlob(course, id)
			.then((data) => {
				setScriptData(data);
			})
			.catch((error) => {
				console.error(error);
			});
	}, [course, id]);

	const submitCode = async () => {
		setLoadOutput("loading");

		let requestBody;
		let plotFlag = 0; // maintains the status of the flag

		requestBody = {
			source_code: btoa(replaceReadCSVLine(inputCode)),
			language_id: 10,
		};

		// if plots are to be displayed
		if (plotData) {
			// for plot folder
			if (inputCode === scriptData.code) {
				try {
					const response = await axios.get(
						`${process.env.REACT_APP_PLOT_FILE}/${plotData}.png`,
						{
							responseType: "blob",
						}
					);
					const imageUrl = URL.createObjectURL(response.data);
					setImageUrl(imageUrl);
					setLoadOutput("loaded");
					plotFlag = 1; // plot has been successfully displayed
				} catch (error) {
					// if the plot doesn't already exist inside the plot folder create a new one
					requestBody = {
						source_code: btoa(
							replaceReadCSVLine(
								replacePltShowLine(inputCode, "plots")
							)
						),
						language_id: 10,
					};
					plotFlag = 2; // plot is created in the "plots" folder
					// console.log(error);
				}
			}
			// for modifedPlot folder
			else {
				requestBody = {
					source_code: btoa(
						replaceReadCSVLine(
							replacePltShowLine(inputCode, "modifiedPlots")
						)
					),
					language_id: 10,
				};
				plotFlag = 3; // plot is created in the "modifiedPlots" folder
			}
		}

		try {
			const response = await axios.post(
				`${process.env.REACT_APP_SERVER_URL_PROD}?base64_encoded=true&wait=true`,
				requestBody
			);
			// if there are no errors i.e. success response
			if (response.data.status?.id === 3) {
				setErrorStatus(0);
				setOutputCode(atob(response.data.stdout));
				if (!response.data.stdout) {
					setOutputCode("");
				}

				if (plotFlag === 2) {
					const response = await axios.get(
						`${process.env.REACT_APP_PLOT_FILE}/${plotData}.png`,
						{
							responseType: "blob",
						}
					);
					const imageUrl = URL.createObjectURL(response.data);
					setImageUrl(imageUrl);
				} else if (plotFlag === 3) {
					const response = await axios.get(
						`${process.env.REACT_APP_MODIFIED_PLOT_FILE}/${plotData}.png`,
						{
							responseType: "blob",
						}
					);
					const imageUrl = URL.createObjectURL(response.data);
					setImageUrl(imageUrl);
				}
				setLoadOutput("loaded");
				return;

				// if there's some error in the response from judge0 api
			} else {
				setErrorStatus(1);
				setOutputCode(atob(response.data.stderr));
				setLoadOutput("loaded");

				return;
			}
		} catch (error) {
			// if there's some error in the axios request
			setTimeout(() => {
				setLoadOutput("loaded");
			}, 5000);
			console.error(error);
		}
	};

	// if there's a plot to be made then show no output (keeping the console empty)
	useEffect(() => {
		// if (plotData) {
		//   setFinalOutput(`Your Output:\n`);
		// } else {
		setFinalOutput(`Your Output:\n${outputCode}`);
		// }
		setLoadOutput("loaded");
	}, [outputCode]);

	// load the script data as soon as it's ready
	useEffect(() => {
		if (scriptData) {
			setInputCode(scriptData.code);
		}
	}, [scriptData]);

	function handleInputChange(value) {
		setInputCode(value);
	}

	// sets the editor-script modified by the user to it's original content
	function handleReset() {
		setInputCode(scriptData.code);
		setOutputCode("");
	}

	function handleCodeTab() {
		setTab("code");
	}

	function handleDojobotTab() {
		setTab("DojoBot");
	}

	// replace plt.show() with relevant code to display it properly
	function replacePltShowLine(inputString, folder) {
		const updatedString = inputString.replace(
			/plt\.show\(\)/g,
			`from azure.storage.blob import BlobServiceClient
import os
filename = '${plotData}.png'
plt.savefig(filename)
folder_name = '${folder}/'
blob_name = folder_name + filename
account_name = 'staticasssets'
account_key = '${process.env.REACT_APP_BLOB_ACCOUNT_KEY}'
container_name = '${process.env.REACT_APP_CONTAINER_NAME}'
blob_service_client = BlobServiceClient(account_url=f"https://{account_name}.blob.core.windows.net", credential=account_key)
blob_client = blob_service_client.get_blob_client(container=container_name, blob=blob_name)
with open(filename, 'rb') as file:
  blob_client.upload_blob(file, overwrite=True)
os.remove(filename)
print(f"Press the Green Icon to drag the plot")`
		);

		return updatedString;
	}

	// replace the pd.read statement to some relevant code
	function replaceReadCSVLine(inputString) {
		const headers = {
			"User-Agent":
				"Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.3",
		};

		const urlRegex =
			/(\b\w*df)\s*=\s*pd\.(read_csv|read_excel)\(['"]([^'"]+)['"]\)/g;
		const matches = Array.from(inputString.matchAll(urlRegex));

		if (matches.length === 0) {
			return inputString; // Return the input string unchanged if no matches found
		}

		let updatedString = inputString;
		for (const match of matches) {
			const variableName = match[1]; // Extract the variable name ending with 'df' or just 'df'
			const method = match[2]; // Extract the method name (read_csv or read_excel)
			const url = match[3]; // Extract the URL from the matched pattern

			const replacement = `import requests
from io import ${method === "read_csv" ? "StringIO" : "BytesIO"}
import pandas as pd

url = '${url}'
headers = ${JSON.stringify(headers)}
response = requests.get(url, headers=headers)
data = ${
				method === "read_csv"
					? "StringIO(response.text)"
					: "BytesIO(response.content)"
			}
${variableName} = pd.${method}(data)`;

			updatedString = updatedString.replace(match[0], replacement);
		}

		return updatedString;
	}

	const removeImage = () => {
		setImageUrl("");
	};
	function resetChatVariable() {
		setClear(false);
	}
	function clearChat() {
		setClear(true);
	}

	return (
		<Fragment>
			<Tabs>
				<TabList style={{backgroundColor:"#1a202c", color: "white", width: "100%"}}>
					<Tab style={{width: "50%", textAlign:"center"}}><BsCodeSlash
								size={18}
								style={{ marginRight: "7px" }}
							></BsCodeSlash>
							code.py</Tab>
					<Tab style={{width: "50%", textAlign:"center"}}><img
								src={dsdLogo}
								alt="DSD Logo"
								width="14vw"
								height="18vh"
								style={{ marginRight: "6px" }}
							/>
							AI Tutor</Tab>
				</TabList>

				<TabPanel style={{backgroundColor:"#1a202c"}}>
					 <Flex
						border="10px"
						p="5px"
						borderColor="white"
						className="firstC"
						paddingTop="10px"
					>
						<Flex
							borderColor="white"
							direction="column"
							className="fullw"
							marginRight="5px"
						>
							<Heading
								as="h2"
								className="text-mono"
								color="#ffffffeb"
								fontSize="20px"
								fontWeight="600"
							>
								Code Editor
							</Heading>
							<Editor
								options={{
									fontSize: 13,
									minimap: {
										enabled: false,
									},
								}}
								height="400px"
								width="100%"
								className="editorStyle"
								borderRadius="0.375rem"
								defaultLanguage="python"
								value={inputCode}
								onChange={handleInputChange}
								theme="vs-dark"
								loading="Loading. . ."
							/>
							<Box
								className="editorButton down"
								backgroundColor={"#1a202c"}
								marginTop="0.5rem"
							>
								<Button
									title="Execute the code"
									borderRadius="0.375rem"
									paddingTop="5px"
									paddingBottom="5px"
									onClick={() => submitCode()}
								>
									<PiPlayFill
										size={15}
										style={{ marginRight: "3px" }}
									>
										{" "}
									</PiPlayFill>
									Run
								</Button>

								<Button
									title="Reset to the original code"
									onClick={handleReset}
									borderRadius="0.375rem"
									paddingTop="5px"
									paddingBottom="5px"
								>
									{" "}
									<PiArrowsCounterClockwiseFill
										size={15}
										style={{ marginRight: "3px" }}
									>
										{" "}
									</PiArrowsCounterClockwiseFill>
									Reset
								</Button>
								{loadOutput === "loading" ? (
									<Spinner className="spin"></Spinner>
								) : (
									""
								)}
							</Box>
						</Flex>

						<Flex
							color="#ffffffeb"
							direction="column"
							backgroundColor="#1a202c"
							className="fullw"
							marginLeft="5px"
						>
							<Box backgroundColor="#1a202c" w="100%">
								<Heading
									as="h2"
									className="text-mono"
									color="#ffffffeb"
									fontSize="20px"
									fontWeight="600"
								>
									Console
								</Heading>
								<Box
									height="407px"
									minHeight="407px"
									overflowY="auto"
								>
									<textarea
										spellCheck="false"
										value={finalOutput}
										style={style}
                                        readOnly
										className="scroll"
									></textarea>
								</Box>
							</Box>
						</Flex>
					</Flex>
				</TabPanel>
				<TabPanel style={{backgroundColor:"#1a202c"}}>
					<Flex
						className="down"
						backgroundColor="#1a202c"
						w="100%"
						justifyContent={"space-between"}
						paddingTop="10px"
					>
						<Heading
							as="h2"
							className="text-mono"
							color="#ffffffeb"
							fontSize="20px"
							fontWeight="600"
							marginLeft="1rem"
						>
							AI Tutor
						</Heading>
						<Button style={{marginRight:"1rem"}} title="Clear" onClick={() => clearChat()}>
							<PiBroomFill
								size={15}
								style={{ marginRight: "3px" }}
							>
								{" "}
							</PiBroomFill>
							Clear
						</Button>
					</Flex>
					<AiCopilot2
						errorStatus={errorStatus}
						outputCode={outputCode}
						scriptData={scriptData}
						theme={style.backgroundColor}
						clear={clear}
						resetChatVariable={resetChatVariable}
					></AiCopilot2>
				</TabPanel>
			</Tabs>

			{/* <ChakraProvider>
			<Box>
				
				<Tabs
					isFitted
					variant="unstyled"
					textColor="#fff"
					width="100%"
					backgroundColor="#1a202c"
					h="510px"
				>
					<TabList mb="10px">
						<Tab className="code-tab">
							<BsCodeSlash
								size={18}
								style={{ marginRight: "7px" }}
							></BsCodeSlash>
							code.py
						</Tab>
						<Tab className="tutor-tab">
							<img
								src={dsdLogo}
								alt="DSD Logo"
								width="12vw"
								height="12vh"
								style={{ marginRight: "6px" }}
							/>
							AI Tutor
						</Tab>
						
					</TabList>
					<TabIndicator
						mt="-1.5px"
						height="2px"
						bg="#fafcfe"
					/>
					<TabPanels>
						<TabPanel backgroundColor="#1a202c">
							<Flex
								border="10px"
								p="5px"
								borderColor="white"
								className="firstC"
							>
								<Flex
									borderColor="white"
									direction="column"
									className="fullw"
									marginRight="5px"
								>
									<Heading
										as="h2"
										className="text-mono"
										color="#ffffffeb"
										fontSize="20px"
										fontWeight="600"
									>
										Code Editor
									</Heading>
									<Editor
										options={{
											fontSize: 13,
											minimap: {
												enabled: false,
											},
										}}
										height="300px"
										width="100%"
										className="editorStyle"
										borderRadius="0.375rem"
										defaultLanguage="python"
										value={inputCode}
										onChange={handleInputChange}
										theme="vs-dark"
										loading="Loading. . ."
									/>
									<Box
										className="editorButton down"
										backgroundColor={"#1a202c"}
										marginTop="0.5rem"
									>
										<Button
											title="Execute the code"
											borderRadius="0.375rem"
											paddingTop="5px"
											paddingBottom="5px"
											onClick={() => submitCode()}
										>
											{" "}
											<PiPlayFill
												size={15}
												style={{ marginRight: "3px" }}
											>{" "}</PiPlayFill>
											Run
										</Button>

										<Button
											title="Reset to the original code"
											onClick={handleReset}
											borderRadius="0.375rem"
											paddingTop="5px"
											paddingBottom="5px"
										>
											{" "}
											<PiArrowsCounterClockwiseFill
												size={15}
												style={{ marginRight: "3px" }}
											>
												{" "}
											</PiArrowsCounterClockwiseFill>
											Reset
										</Button>
										{loadOutput === "loading" ? (
											<Spinner className="spin"></Spinner>
										) : (
											""
										)}
									</Box>
								</Flex>

								<Flex
									color="#ffffffeb"
									direction="column"
									backgroundColor="#1a202c"
									className="fullw"
									marginLeft="5px"
								>
									<Box backgroundColor="#1a202c" w="100%">
										<Heading
											as="h2"
											className="text-mono"
											color="#ffffffeb"
											fontSize="20px"
											fontWeight="600"
										>
											Console
										</Heading>
										<Box
											height="19rem"
											minHeight="5rem"
											overflowY="auto"
										>
											<textarea
												spellCheck="false"
												value={finalOutput}
												style={style}
												className="scroll"
											></textarea>
										</Box>
									</Box>
								</Flex>
							</Flex>
						</TabPanel>
						<TabPanel>
							<Flex
								className="down"
								backgroundColor="#1a202c"
								w="100%"
								justifyContent={"space-between"}
							>	
								<Heading
											as="h2"
											className="text-mono"
											color="#ffffffeb"
											fontSize="20px"
											fontWeight="600"
											marginLeft=".8vw"
										>
											AI Tutor
								</Heading>
								<Button
									title="Clear"
									onClick={() => clearChat()}
								>
									<PiBroomFill size={15}
									style={{ marginRight: "3px" }}>
									{" "}</PiBroomFill>
									 Clear
								</Button>
							</Flex>
							<AiCopilot2
								errorStatus={errorStatus}
								outputCode={outputCode}
								scriptData={scriptData}
								theme={style.backgroundColor}
								clear={clear}
								resetChatVariable={resetChatVariable}
							></AiCopilot2>
						</TabPanel>
					</TabPanels>
				</Tabs>
			</Box>
		</ChakraProvider> */}
		</Fragment>
	);
}

export default MonacoEditor;
